import { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Formik, Form as FormikForm, Field } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { expitationTime } from "../../../../helpers/expirationTime";
import { getData, postData } from "../../../../services/index";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Error from "../../../atoms/Error";
import PasswordField from "../../../atoms/PasswordField";
// import styles from "../forms.module.scss";
import { numberToNumeric } from "../../../../helpers/numberToNumeric";
import useRecaptcha from "../../../../hooks/useRecaptcha";
import ReCaptchaTerms from "../../../atoms/RecaptchaTerms";

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordValue, setPasswordValue] = useState(false);
  const passwordRef = useRef(null);
  const [businessDetails, setBusinessDetails] = useState();
  const [cookie, setCookie] = useCookies(["buid", "lbid"]);
  const [pageError, setPageError] = useState(undefined);
  // State to track reCAPTCHA token refresh
  const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);

  // Call the useRecaptcha hook and pass the `refreshRecaptcha` state to regenerate the token when needed
  const recaptchaToken = useRecaptcha("LOGIN", refreshRecaptcha);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const recaptchaResponse = await getData({
      endpoint: "EmployeeAuth/VerifyRecaptcha",
      params: { token: recaptchaToken, expectedAction: "LOGIN" },
    });

    if (recaptchaResponse?.riskScore > 0.5) {
      try {
        const lowercaseEmail = values.email.toLowerCase();
        const updatedValues = {
          ...values,
          email: lowercaseEmail,
          business_id: cookie?.lbid,
        };
        dispatch(setLoading(true));

        const { data: resultData } = await postData({
          endpoint: "BusinessAuth/commonLogin",
          data: {},
          params: updatedValues,
        });

        if (resultData.status && resultData.response === 200) {
          dispatch(setLoading(false));
          setCookie("buid", resultData.data.user_id, {
            path: "/",
            expires: expitationTime(),
            // secure: true,
          });

          if (resultData.data.is_business_user) {
            setCookie("both", btoa(resultData.data.is_business_user), {
              path: "/",
              expires: expitationTime(),
              // secure: true,
            });
          }
          setCookie("role", btoa(resultData.data.role), {
            path: "/",
            expires: expitationTime(),
            // secure: true,
          });
          localStorage.setItem("countdownStartTime", "30");
          navigate("/auth/verify");
          toast.success(resultData.message, {
            duration: 1000,
            style: confimationStyles,
          });
        } else {
          toast.error(resultData.message, {
            duration: 1000,
            style: errorStyles,
          });

          setRefreshRecaptcha((prev) => !prev);

          // When the password is incorrect, refresh the recaptcha token
          if (resultData.response === 401) {
            if (resultData?.data?.limit_left > 0) {
              const triesLeft = numberToNumeric[resultData?.data?.limit_left];
              setPageError(
                `You've only ${triesLeft} tr${
                  resultData?.data?.limit_left === 1 ? "y" : "ies"
                } remaining`
              );
            } else if (resultData?.data?.limit_left === 0) {
              setPageError("No attempts left");
            } else {
              setPageError(undefined);
            }
          } else {
            setPageError(undefined);
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
        toast.error("Error Occurred", {
          duration: 1000,
          style: errorStyles,
        });
        setRefreshRecaptcha((prev) => !prev);
      } finally {
        setSubmitting(false);
      }
    } else {
      toast.error("Recaptcha Not Verified", {
        duration: 1000,
        style: errorStyles,
      });

      // If recaptcha is not verified, refresh the token
      setRefreshRecaptcha((prev) => !prev); // Toggle to regenerate token

      setSubmitting(false);
    }
  };

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   try {
  //     const lowercaseEmail = values.email.toLowerCase();
  //     const updatedValues = { ...values, email: lowercaseEmail };
  //     dispatch(setLoading(true));
  //     const { data: resultData } = await postData({
  //       endpoint: "BusinessAuth/commonLogin",
  //       data: {},
  //       params: updatedValues,
  //     });

  //     if (resultData.status && resultData.response === 200) {
  //       dispatch(setLoading(false));
  //       setCookie("buid", resultData.data.user_id, {
  //         path: "/",
  //         expires: expitationTime(),
  //         // secure: true,
  //       });

  //       if (resultData.data.is_business_user) {
  //         setCookie("both", btoa(resultData.data.is_business_user), {
  //           path: "/",
  //           expires: expitationTime(),
  //           // secure: true,
  //         });
  //       }
  //       setCookie("role", btoa(resultData.data.role), {
  //         path: "/",
  //         expires: expitationTime(),
  //         // secure: true,
  //       });
  //       localStorage.setItem("countdownStartTime", "30");
  //       navigate("/auth/verify");
  //       toast.success(resultData.message, {
  //         duration: 1000,
  //         style: confimationStyles,
  //       });
  //     } else {
  //       toast.error(resultData.message, {
  //         duration: 1000,
  //         style: errorStyles,
  //       });
  //       if (resultData.response === 401) {
  //         if (resultData?.data?.limit_left > 0) {
  //           const triesLeft = numberToNumeric[resultData?.data?.limit_left];
  //           setPageError(
  //             `You've only ${triesLeft} tr${
  //               resultData?.data?.limit_left === 1 ? "y" : "ies"
  //             } remaining`
  //           );
  //         } else if (resultData?.data?.limit_left === 0) {
  //           setPageError("No attempts left");
  //         } else {
  //           setPageError(undefined);
  //         }
  //       } else {
  //         setPageError(undefined);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error during login:", error);
  //     toast.error("Error Occured", {
  //       duration: 1000,
  //       style: errorStyles,
  //     });
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>

            <Field
              type="text"
              name="email"
              placeholder="Enter Email Address"
              className={`${
                touched.email && errors.email ? "error-input" : ""
              }`}
              autoComplete="off"
              autoFocus
              onInput={(e) => {
                // Remove spaces while typing
                e.target.value = e.target.value.replace(/\s/g, "");
              }}
            />
            {touched.email && errors.email && <Error error={errors.email} />}
          </div>

          <div className="form-group mt-4">
            <PasswordField
              label="Password"
              id="password"
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              className={`${
                (touched.password || isSubmitting) && errors.password
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.password || isSubmitting) && errors.password && (
              <Error error={errors.password} />
            )}
          </div>

          {pageError && <Error error={pageError} />}

          <button
            className="btn btn-primary w-100 mt-4"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Loading" : "Login"}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            className={`btn btn-outline-dark w-100 mt-4`}
            onClick={() => navigate("/auth/forgot-password")}
            type="button"
          >
            Forgot Password?
          </button>

          <ReCaptchaTerms classes="mt-3 mb-3" />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
