import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Logo from "../../../assets/branding/main-logo.png";
import style from "./authlayout.module.scss";
import { getData } from "../../../services";
import { useCookies } from "react-cookie";
import { expitationTime } from "../../../helpers/expirationTime";

const AuthLayout = ({ children }) => {
  const [businessDetails, setBusinessDetails] = useState();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["lbid"]);

  function getSubdomain(url) {
    const parsedUrl = new URL(url);
    const hostnameParts = parsedUrl.hostname.split(".");

    if (hostnameParts.length > 2) {
      return hostnameParts[0];
    }

    return null;
  }
  const getDetailsByDomainName = async (subDomain) => {
    const res = await getData({
      endpoint: "BusinessAuth/GetBusinessByDomainName",
      params: {
        domainName: subDomain,
      },
    });

    if (res && res?.data) {
      setBusinessDetails(res?.data);
      setCookie("lbid", res?.data?.id, {
        path: "/",
        expires: expitationTime(),
      });
    }
  };

  useEffect(() => {
    const pathName = window.location.href;
    if (!pathName.includes("http://localhost")) {
      const subDomain = getSubdomain(pathName);
      getDetailsByDomainName(subDomain);
    }
  }, []);

  return (
    <div className={style.auth_screen}>
      {/* <div className={style.auth_image}>
        <img
        src={Logo}
        className={style.logo_image}
        alt="company-logo"
        loading="eager"
      /> */}
      {/* </div> */}
      <div className={style.auth_content}>
        <div className={style.auth_brand_logo}>
          <img
            src={
              businessDetails && businessDetails?.logo
                ? businessDetails?.logo
                : Logo
            }
            alt="Logo"
          />
        </div>
        <div className={style.auth_child}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
